// import AlertModal from "components/AlertModal";
// import ConfirmModal from "components/ConfirmModal";
// import AlertContextProvider from "context/AlertContextProvider";
// import ConfirmContextProvider from "context/ConfirmContextProvider";
// import UserCXT from "context/UserContext";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import CheckoutPage from "routes/checkout";
// import CategoriesOrderPage from "routes/list/category-order";
// import EditListPage from "routes/list/edit";
// import ListParticipantsPage from "routes/list/participants";
// import SplitPage from "routes/list/split";
// import ListsPage from "routes/lists";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import NotFoundPage from "./routes/404";
import Root from "./routes/Root";
// import InviteToJoin from "./routes/invite-to-list";
// import Join, { loader as joinLoader } from "./routes/join";
// import ListPage, { loader as listLoader } from "./routes/list";
// import PremiumInfo from "./routes/premium-info";
// import Privacy from "./routes/privacy";
// import Share, { loader as shareLoader } from "./routes/share";
// import TermsOfService from "./routes/terms-of-service";
import Hotjar from "@hotjar/browser";
import App from "./App";
import AlertModal from "./components/AlertModal";
import ConfirmModal from "./components/ConfirmModal";
import AlertContextProvider from "./context/AlertContextProvider";
import ConfirmContextProvider from "./context/ConfirmContextProvider";
import Contact from "./routes/contact";
import Event from "./routes/event";
import Guests from "./routes/event/guests";
import Messages from "./routes/event/messages";
import SchedulerPage from "./routes/event/scheduler";
import InitPage from "./routes/init";
import Login from "./routes/login";
import New from "./routes/new";
import Payment from "./routes/payment";
import Privacy from "./routes/privacy";
import Terms from "./routes/terms";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const siteId = 4993862;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        // errorElement: <NotFoundPage />,
        children: [
            {
                path: "/",
                element: <App />,
            },
            // {
            //     path: "/init",
            //     element: <InitPage />,
            // },
            // {
            //     path: "/login",
            //     element: <Login />,
            // },
            // {
            //     path: "/terms",
            //     element: <Terms />,
            // },
            // {
            //     path: "/privacy",
            //     element: <Privacy />,
            // },
            // {
            //     path: "/contact",
            //     element: <Contact />,
            // },
            // {
            //     children: [
            //         {
            //             path: "/event/:eventId",
            //             element: <Event />,
            //         },
            //         {
            //             path: "/event/:eventId/guests",
            //             element: <Guests />,
            //         },
            //         {
            //             path: "/event/:eventId/messages",
            //             element: <Messages />,
            //         },
            //         {
            //             path: "/event/:eventId/scheduler",
            //             element: <SchedulerPage />,
            //         },
            //     ],
            // },
            // {
            //     path: "/payment/:eventId",
            //     element: <Payment />,
            // },
            // {
            //     path: "/new",
            //     element: <New />,
            // },
        ],
    },
]);

root.render(
    <React.StrictMode>
        <ConfirmContextProvider>
            <AlertContextProvider>
                <RouterProvider router={router} />
                <ConfirmModal />
                <AlertModal />
            </AlertContextProvider>
        </ConfirmContextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
